import { USER_LOADING, USER_LOADED, USER_LOGOUT, IS_LOGIN_USER } from 'constants/user';
const initialUser = () => {
  const token = window.localStorage.getItem('accessToken');
  return { authorized: !!token };
};

const initialState = {
  data: null,
  loading: true,
  authorized: false,
  ...initialUser(),
  isLogin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return { ...state, loading: action.payload };
    case USER_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT:
      return { ...state, authorized: false };
    case IS_LOGIN_USER:
      return { ...state, isLogin: action.payload };
    default:
      return state;
  }
};
