export const companyTypes = [
  { label: 'Single location', value: 'single' },
  { label: 'Corporate', value: 'corporate' },
  { label: 'Franchise', value: 'franchise' },
  { label: 'Virtual kitchen', value: 'virtual' },
];

export const companyTypesEnum = {
  SINGLE: 'single',
  CORPORATE: 'corporate',
  FRANCHISE: 'franchise',
  VIRTUAL_KITCHEN: 'virtual',
  BRAND: 'brand',
};
