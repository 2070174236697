import {
  ADD_TABLE_TO,
  GET_TABLE_TO,
  UPDATE_TABLE_TO,
  DELETE_TABLE_TO,
  ADD_SECTION_TO,
  GET_SECTION_TO,
  UPDATE_SECTION_TO,
  DELETE_SECTION_TO,
  GET_SETTINGS_TO,
  CREATE_BUSINESS_TO,
  SET_LOADING_TO,
  SET_IS_BEEPING,
} from 'redux/actions/types';

const initialState = {
  business: {},
  tables: [],
  sections: [],
  settings: {},
  loading: false,
  isBeeping: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TABLE_TO:
      return { ...state, tables: payload };
    case GET_TABLE_TO:
      return { ...state, tables: payload };
    case DELETE_TABLE_TO:
      return { ...state, tables: payload };
    case UPDATE_TABLE_TO:
      return { ...state, tables: payload };
    case ADD_SECTION_TO:
      return {
        ...state,
        sections: payload,
      };
    case GET_SECTION_TO:
      return { ...state, sections: payload };
    case DELETE_SECTION_TO:
      return { ...state, sections: payload };
    case UPDATE_SECTION_TO:
      return { ...state, sections: payload };
    case CREATE_BUSINESS_TO:
      return { ...state, business: payload };
    case GET_SETTINGS_TO:
      return { ...state, settings: payload };
    case SET_LOADING_TO:
      return { ...state, loading: payload };
    case SET_IS_BEEPING:
      return { ...state, isBeeping: payload };
    default:
      return state;
  }
};
