import doordash from "../assets/images/doordash.png";
import postmates from "../assets/images/postmates.png";
import ubereats from "../assets/images/ubereats.png";
import doordash_l from "../assets/images/doordash_large.png";
import postmates_l from "../assets/images/postmates_large.png";
import ubereats_l from "../assets/images/ubereats_large.png";
import eatstreet from "../assets/images/eatstreet_large.png";
import doocado from "../assets/images/doocado.jpeg";
import deliverycom from "../assets/images/deliverycom_large.png";
import table_ordering from "../assets/images/table_ordering.png";
import eatstreet_l from "../assets/images/eatstreet.png";
import website from "../assets/images/website.jpg";
import Logo from "../assets/images/logo.png";
import grubshubV2 from "../assets/images/GrubhubV2.svg";

export const largeIcons = {
  main: Logo,
  website: website,
  grubhub: grubshubV2,
  doordash: doordash_l,
  postmates: postmates_l,
  ubereats: ubereats_l,
  eatstreet: eatstreet_l,
  doocado,
  deliverycom,
  grubhubv2: grubshubV2,
  table_ordering,
};

export const smallIcons = {
  grubhub: grubshubV2,
  doordash,
  postmates,
  ubereats,
  eatstreet,
  website,
  doocado,
  deliverycom,
  grubhubv2: grubshubV2,
  table_ordering,
};
