import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import instance from '../../services/api';
import { gatewayInstance } from '../../services/api';
import { getMenus } from './Menu/menu';
import { PROVIDERS_START_RESUME } from 'redux/actions/types';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';

export const getProviders = () => async (dispatch, getState) => {
  const {
    business: { currentBusiness },
  } = getState();
  if (
    !currentBusiness?.roles?.name ||
    currentBusiness?.roles?.name === 'user' ||
    currentBusiness?.roles?.name === 'market_place_owner'
  )
    return;
  dispatch({ type: 'PROVIDERS_REQ' });
  return instance
    .get('/providers')
    .then(({ data: { data } }) => {
      return dispatch({
        type: 'PROVIDERS_RES',
        payload: {
          items: Object.values(data.providers),
          providerId: data._id,
          providersSettings: data.providersSettings,
        },
      });
    })
    .catch(() => {
      dispatch({ type: 'PROVIDERS_ERR' });
    });
};

export const getProviderStatus = (provider) => (dispatch) => {
  return instance.get(`/providers/${provider}/status`).then(({ data: { data } }) => {
    return data;
  });
};
export const handleProviderConnectData = (data) => (dispatch, getState) => {
  const { providers } = getState();
  if (providers.targetProviderConnect === data?.data?.provider) {
    return dispatch({ type: 'PROVIDER_CONNECT_DATA_RESPONSE', payload: data });
  }
};

export const connectStoreProviderUber = (body) => (dispatch, getState) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  const { providers } = getState();

  return gatewayInstance
    .post('ubereats/provider/connect/', {
      ...body,
      redirectUri: process.env.REACT_APP_API_PROD_URL,
    })
    .then((res) => {
      const {
        data: {
          body: {
            providers: {
              ubereats: { connected, state, status, srv },
            },
          },
        },
      } = res;

      const updateUberObj = {
        enabled: connected,
        id: providers?.providerId,
        provider: srv,
        state: state,
        status: status,
      };
      return dispatch(updateProvider(updateUberObj));
    })
    .catch((err) => {
      console.log('err: ', err);

      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const connectStoreProvider = (body) => (dispatch, getState) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  const { providers } = getState();
  const copyProviders = [...providers.items];
  return instance
    .post('providers/connect/', {
      ...body,
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      return dispatch({
        type: 'PROVIDERS_RES',
        payload: { items: Object.values(data.providers) },
      });
    })
    .catch((err) => {
      // toast.error(err.body || 'Error Connect Provider');
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const connectProviderUber = (code, state) => (dispatch, getState) => {
  const { providers } = getState();
  const copyProviders = [...providers.items];
  dispatch({ type: 'PROVIDERS_REQ' });
  return gatewayInstance
    .post(`ubereats/provider/connect/`, {
      code,
      state,
      redirectUri: process.env.REACT_APP_API_PROD_URL,
    })
    .then((res) => {
      const {
        data: { body },
      } = res;
      copyProviders.forEach((item, index) => {
        if (item.srv === body.providers.ubereats.srv) {
          copyProviders[index] = { ...body.providers.ubereats };
        }
      });
      dispatch({
        type: 'PROVIDERS_RES',
        payload: providers,
      });
      return body;
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

// export const disconnectProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/disconnect/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const enableProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/enable/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const disableProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/disable/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const settingsProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/settings/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

export const connectProviderLocation = (body) => (dispatch) => {
  body.data.username = body.data.username.trim();
  return instance
    .post('/providers/connect', body)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const disconnectProvider = (id) => (dispatch) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  return instance
    .get(`/providers/disconnect/${id}`)
    .then(({ data: { data } }) => {
      dispatch(getMenus());
      return dispatch({
        type: 'PROVIDERS_RES',
        payload: { items: Object.values(data.providers), providerId: data._id },
      });
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const updateProvider = (body) => (dispatch) => {
  return instance.put('/providers/status', body);
};

export const updateProviderState = (body) => (dispatch) => {
  return instance.put('/providers/state', body).then(() => {
    // dispatch(getProviders());
  });
};

export const updateProviderConfirmOrder = (body) => (dispatch) => {
  return instance.put('/providers/confirm-order', body).then(() => {
    dispatch(getProviders());
  });
};

export const updateStopResumeOrders = (data) => (dispatch) => {
  dispatch({ type: PROVIDERS_START_RESUME, payload: data });
};

export const getProvidersStatuses = () => async (dispatch) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  return instance
    .get(`/providers/statuses`)
    .then(({ data: { data } }) => {
      if (
        (data.status === PAUSE_RESUME_STATUSES.PARTIAL || !data.status || !data.paused_until) &&
        data.status !== PAUSE_RESUME_STATUSES.RECEIVING
      ) {
        data.status = PAUSE_RESUME_STATUSES.PAUSED;
        data.paused_until = Math.floor(moment().valueOf() / 1000) + 100000000;
      }

      dispatch(updateProviderResumePauseStatuses(data));
      return data;
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(updateStopResumeOrders({ loading: false }));
      }, 2000);
    });
};

export const updateProviderResumePauseStatuses = (data, fromSocket = false) => (dispatch) => {
  if (
    (data.status === PAUSE_RESUME_STATUSES.PARTIAL || !data.status || !data.paused_until) &&
    data.status !== PAUSE_RESUME_STATUSES.RECEIVING
  ) {
    data.status = PAUSE_RESUME_STATUSES.PAUSED;
    data.paused_until = Math.floor(moment().valueOf() / 1000) + 100000000;
  }
  dispatch({ type: 'UPDATE_PROVIDER_RESUME_PAUSE_STATUSES', payload: { ...data } });
  setTimeout(() => {
    dispatch(updateStopResumeOrders({ loading: false }));
    dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
  }, 2000);
};

export const stopProvider = () => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  const {
    providers: { stopResumeOrders },
  } = getState();
  const { provider, data } = stopResumeOrders;

  return instance
    .post(`/providers/suspension/pause/${provider}`, data)
    .then((res) => {
      dispatch(getProviders());
      const {
        data: { data },
      } = res;

      let message = '';
      if (provider === 'all') {
        if (data.status === PAUSE_RESUME_STATUSES.PARTIAL) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
        }
      } else {
        if (!data.data[provider]) {
          toast.error(`Stop taking orders request has been failed for ${provider}.`);
        }
      }

      if (message) {
        toast.error(message);
      }

      dispatch(updateStopResumeOrders({ showModal: false }));
    })
    .catch((err) => {});
  // .finally(() => {
  //   dispatch(updateStopResumeOrders({ loading: false, showModal: false }));
  //   dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
  // });
};

export const resumeProvider = () => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  const {
    providers: { stopResumeOrders },
  } = getState();
  const { provider } = stopResumeOrders;
  instance.post(`/providers/suspension/resume/${provider}`).then((res) => {
    const {
      data: { data },
    } = res;

    dispatch(getProviders());
    if (provider === 'all') {
      if (data.status === PAUSE_RESUME_STATUSES.PARTIAL) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
      }
    } else {
      if (!data.data[provider]) {
        toast.success(`Stop taking orders request has been failed for ${provider}.`);
      }
    }
    dispatch(updateStopResumeOrders({ showModal: false }));
  });
  // .finally((err) => {
  //   dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
  // });
};

export const busyProvider = (data) => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  const {
    providers: { stopResumeOrders },
  } = getState();
  const { data } = stopResumeOrders;
  const model = {
    // until: '',
    duration: 3600,
    additional_time: data.duration,
    // last_store_status: NORMAL,
    additional_time_unit: data.unit,
    requested_store_status: PAUSE_RESUME_STATUSES.BUSY,
  };
  return instance
    .post('/providers/busy', model)
    .catch((err) => {
      toast.error('Oops! Something went wrong. Please try again later.');
    })

    .finally(() =>
      setTimeout(() => {
        dispatch(updateStopResumeOrders({ loading: false, showModal: false }));
      }, 2000)
    );
};

export const updateProviderMenuFee = (data) => (dispatch) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  return instance
    .patch('/providers/service-fee', data)
    .catch((err) => {
      toast.error(err?.response?.message || 'Oops! Something went wrong.');
    })
    .then(() => {
      dispatch(getProviders());
    })
    .finally(() => dispatch({ type: 'PROVIDERS_ERR' }));
};

export const pinCodeCheck = (code) => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));

  return instance
    .post(`business/pin-code/check/${code}`)

    .finally(() => {
      dispatch(updateStopResumeOrders({ loading: false }));
    });
};
