import { combineReducers } from 'redux';
import { USER_LOGOUT } from 'constants/user';

import providers from './providers';
import orders from './orders';
import user from './user';
import menuV2 from './menuV2';
import events from './events';
import printers from './printers';
import business from './business';
// import menu from './menu';
import reports from './reports';
import coupons from './coupons';
import paymentNotification from './paymentNotification';
import tableOrdering from './tableOrdering';

const appReducer = combineReducers({
  providers,
  orders,
  user,
  menu: menuV2,
  events,
  printers,
  business,
  reports,
  coupons,
  paymentNotification,
  tableOrdering,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
