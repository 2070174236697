const durations = [
  {
    text: "5 mins",
    value: 300,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 5 mins.",
  },
  {
    text: "10 mins",
    value: 600,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 10 mins.",
  },
  {
    text: "15 mins",
    value: 900,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 15 mins.",
  },
  {
    text: "20 mins",
    value: 1200,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 20 mins.",
  },
  {
    text: "30 mins",
    value: 1800,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 30 mins",
  },
  {
    text: "45 mins",
    value: 2700,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 45 mins.",
  },
  {
    text: "1 hour",
    value: 3600,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 1 hour.",
  },
  {
    text: "2 hours",
    value: 7200,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 2 hours.",
  },
];

const durationsBusy = [
  {
    text: "5 mins",
    value: 300,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 5 mins.",
  },
  {
    text: "10 mins",
    value: 600,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 10 mins.",
  },
  {
    text: "15 mins",
    value: 900,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 15 mins.",
  },
  {
    text: "20 mins",
    value: 1200,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 20 mins.",
  },
  {
    text: "25 mins",
    value: 1500,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 25 mins.",
  },
  {
    text: "30 mins",
    value: 1800,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 30 mins",
  },
  {
    text: "35 mins",
    value: 2100,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 35 mins",
  },
  {
    text: "40 mins",
    value: 2400,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 40 mins",
  },
  {
    text: "45 mins",
    value: 2700,
    unit: "seconds",
    description: "- Your store won’t receive new orders for 45 mins.",
  },
];

const startStopInitValues = {
  duration: [
    ...durations,
    {
      text: "Rest of Today",
      description:
        "- Your store won’t receive new orders for the rest of today.",
      value: "today",
      unit: "text",
    },
    {
      text: "Until I Make it Available",
      value: "permanent",
      unit: "text",
      description:
        "- Your store won’t receive new orders until you make it available again",
    },
  ],
  durationsBusy: durationsBusy,
  reason: [
    {
      text: "Restaurant is busy",
      value: "Restaurant is busy",
    },
    {
      text: "Out of inventory",
      value: "Out of inventory",
    },
    {
      text: "Restaurant problem",
      value: "Restaurant problem",
    },
    {
      text: "Other",
      value: "Other",
    },
  ],
  action: [
    {
      text: "Normal",
      value: "RECEIVING",
      description: "Business as usual",
    },
    {
      text: "Busy",
      value: "BUSY",
      description: " Need more prep time",
    },
    {
      text: "Paused",
      value: "PAUSED",
      description: "Pause new orders",
    },
  ],
};

export const PAUSE_RESUME_STATUSES = {
  PAUSED: "PAUSED",
  RECEIVING: "RECEIVING",
  PARTIAL: "PARTIAL",
  LIMITED: "LIMITED",
  THROTTLING: "THROTTLING",
  BUSY: "BUSY",
};

export const PAUSE_RESUME_STATUSES_LABEL = {
  PAUSED: "PAUSED",
  RECEIVING: "ACCEPTING",
  PARTIAL: "PARTIAL",
  LIMITED: "LIMITED",
  THROTTLING: "THROTTLING",
  BUSY: "BUSY",
};

export default startStopInitValues;
