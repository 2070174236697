import instance from '../../services/api';
import {
  SET_CURRENT_BUSINESSES,
  SET_TIMEZONE,
  SET_CUISINES,
  SET_ALL_BRAND,
  SET_IS_LOADING,
  SET_CURRENT_BRAND,
  COLLECT_BUSINESS_IDS_FOR_HISTORY,
} from './types';
import { toast } from 'react-toastify';
import { getUserData } from './user';
import { companyTypesEnum } from 'constants/companyTypes';
import { extractIds } from 'utils/extractIds';

export const updateBusinessSettings = (body) => (dispatch) => {
  return instance
    .put(`/business`, body)
    .then(({ data: { data } }) => {
      dispatch(getUserData());
      return data;
    })
    .catch((err) => {
      toast.error('Updated failed!');
      return Promise.reject(err);
    });
};

export const getBusinessSettings = () => {
  return instance.get('/business').then(({ data: { data } }) => {
    return data;
  });
};

export const getAllBusinessSettings = () => {
  return instance.get('/business/all').then(({ data: { data } }) => {
    return data;
  });
};

export const updateCurrentBusiness = (business) => (dispatch) => {
  business?.type ? localStorage.setItem('type', business?.type) : localStorage.setItem('type', companyTypesEnum.SINGLE);
  if (business) {
    localStorage.setItem(
      'businessInfo',
      JSON.stringify({
        label: `${business.companyName} - ${business?.addressInfo?.address1 || business?.addressInfo?.address || ''}`,
        id: business.id,
      })
    );
  } else {
    localStorage.setItem('businessInfo', JSON.stringify({ label: '', id: '' }));
  }
  dispatch({ type: SET_CURRENT_BUSINESSES, payload: business });
};

export const getTimeZoneList = () => (dispatch) => {
  return instance
    .get(`/timezone`)
    .then(({ data: { data } }) => {
      // data = data.map((item) => ({ ...item, text: item.timeZoneName }));
      dispatch({ type: SET_TIMEZONE, payload: data });
    })
    .catch((err) => {
      console.log('TIMEZONE_GET_ERR', err);
    });
};

export const getCuisines = () => (dispatch) => {
  return instance
    .get(`/business/cuisineType  `)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_CUISINES, payload: data });
    })
    .catch((err) => {
      toast.error('Get Cuisine Type failed!');
      return Promise.reject(err);
    });
};

export const updatePinCode = (data) => (dispatch, getState) => {
  const {
    business: { currentBusiness },
  } = getState();
  return instance
    .put(`business/pin-code`, data)
    .then(() => {
      return dispatch(
        updateCurrentBusiness({ ...currentBusiness, settings: { ...currentBusiness.settings, pinCode: data } })
      );
    })
    .catch((err) => {
      toast.error(err.response.message);
      return Promise.reject(err);
    });
};

export const handleUpdatePinCodeData = (data) => (dispatch, getState) => {
  const {
    business: { currentBusiness },
  } = getState();
  return dispatch(
    updateCurrentBusiness({
      ...currentBusiness,
      settings: {
        ...currentBusiness.settings,
        pinCode: { ...currentBusiness.settings.pinCode, enable: data.enable, code: data.code },
      },
    })
  );
};

export const getCurrentBusiness = (businessId, userId) => (dispatch) => {
  return instance.get(`business/login/${businessId}`).then(({ data: { data } }) => {
    // eslint-disable-next-line array-callback-return
    data?.roles.find((item) => {
      if (item?.userId === userId) {
        data.roles = item.role;
      }
    });
    dispatch(updateCurrentBusiness(data));
    return data;
  });
};

export const getBrandList = () => (dispatch) => {
  dispatch(setLoading(true));
  return instance
    .get(`users/me-v2`)
    .then(({ data: { data } }) => {
      const userBusinessFromStorage = JSON.parse(localStorage.getItem('businessInfo'));
      const initialSelectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
      if (userBusinessFromStorage) {
        dispatch(getCurrentBusiness(userBusinessFromStorage?.id, data?.user._id));
        if (initialSelectedBrand !== null) {
          dispatch({ type: SET_CURRENT_BRAND, payload: data?.brands[0] });
        }
      } else {
        dispatch({ type: SET_CURRENT_BRAND, payload: data?.brands[0] });
        dispatch(getCurrentBusiness(data?.brands[0]?.locations[0]?.businessId, data?.user._id));
      }
      const ids = extractIds(data?.brands);
      dispatch({ type: COLLECT_BUSINESS_IDS_FOR_HISTORY, payload: ids || [] });
      dispatch({ type: SET_ALL_BRAND, payload: data?.brands || [] });
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 500);
    })
    .catch(() => dispatch(setLoading(false)));
};

export const setLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  };
};
