export const convertLocationListForAsyncLoadOption = (list) => {
  const data = [];
  let optionsLocation;
  if (list.length > 0) {
    list.forEach((item) => {
      if (item.value?.locations && item?.value.locations.length > 0) {
        data.push(...item.value?.locations);
      }
    });
  }
  if (data.length > 0) {
    optionsLocation = data.map((item) => ({
      value: item.businessId,
      label: item.locationName,
      name: item.locationName,
      companyName: item?.companyName || '',
    }));
    return optionsLocation;
  } else return [];
};
