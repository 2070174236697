import {
  SET_CURRENT_BUSINESSES,
  SET_TIMEZONE,
  SET_AVAILABILITY_CUSTOM,
  SET_CURRENT_BUSINESSES_DISABLE,
  SET_CURRENT_BUSINESSES_ENABLE,
  SET_CUISINES,
  SET_ALL_BRAND,
  SET_LOCATIONS_LIST,
  SET_CURRENT_BRAND,
  COLLECT_BUSINESS_IDS_FOR_REPORT,
  COLLECT_BUSINESS_IDS_FOR_HISTORY,
  IS_LOCATION_UNSELECT,
  SET_IS_LOADING,
} from 'redux/actions/types';
import { convertLocationListForAsyncLoadOption } from 'utils/convertLocationListForAsyncLoadOption';

const initialState = {
  currentBusiness: {},
  currentSelectedBrand: {},
  timeZone: [],
  availability: [],
  cuisineType: [],
  brandList: [],
  loadingBrand: false,
  businessIdsForReport: [],
  businessIdsForHistory: [],
  filterBrandList: [],
  unSelect: false,
  locationsList: [],
  isBrandLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING:
      return { ...state, isBrandLoading: payload };
    case COLLECT_BUSINESS_IDS_FOR_REPORT:
      return { ...state, businessIdsForReport: payload };
    case COLLECT_BUSINESS_IDS_FOR_HISTORY:
      return { ...state, businessIdsForHistory: payload };
    case IS_LOCATION_UNSELECT:
      return { ...state, unSelect: payload };
    case SET_CURRENT_BUSINESSES:
      return { ...state, currentBusiness: payload };
    case SET_CURRENT_BRAND:
      let convertedBrand = {
        value: payload,
        label: payload.brandName,
        name: `${payload.brandName}`,
        id: payload?._id,
      };
      localStorage.setItem('selectedBrand', JSON.stringify(convertedBrand));
      return { ...state, currentSelectedBrand: convertedBrand };
    case SET_CURRENT_BUSINESSES_DISABLE:
      return {
        ...state,
        currentBusiness: {
          ...state.currentBusiness,
          disabled: true,
        },
      };
    case SET_CURRENT_BUSINESSES_ENABLE:
      return {
        ...state,
        currentBusiness: {
          ...state.currentBusiness,
          disabled: false,
        },
      };
    case SET_CUISINES:
      return {
        ...state,
        cuisineType: payload,
      };
    case SET_TIMEZONE:
      return { ...state, timeZone: payload };
    case SET_AVAILABILITY_CUSTOM:
      return { ...state, availability: payload };
    case SET_ALL_BRAND:
      return { ...state, brandList: payload, filterBrandList: payload };
    case SET_LOCATIONS_LIST:
      const data = convertLocationListForAsyncLoadOption(payload);
      return { ...state, locationsList: data };
    default:
      return state;
  }
};
