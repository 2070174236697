import React, { useEffect, useState, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Typography } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UpdateIcon from "@mui/icons-material/Update";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { SettingsOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useIsWidthDown, useIsWidthUp } from "hooks/mediaQuery";
import {
  doordashMapProvider,
  getMenusV2,
  grubHubMapProvider,
  updateBulk,
} from "redux/actions/Menu/menuV2";
import { getTitle } from "./helper";
import {
  checkLinkedProviderWebsite,
  linkedWithProvider,
} from "helpers/linkedWithProvider";
import {
  SET_LINK_PROVIDER_VISIBILITY,
  SET_LINK_PROVIDER,
  SET_LOADING,
} from "redux/actions/types";
import { PROVIDER_CONNECTED } from "constants/providerStatuses";
import Private from "components/Private";
import { isPermissionWithCompanyType } from "hooks/useCompanyType";
import { getCategoryProductsV2 } from "redux/actions/Menu/products";
import { getModifiersAll } from "redux/actions/Menu/modifiers";
import { MENU_TYPES } from "../../constants/menu";
import CircularProgress from "@mui/material/CircularProgress";

const tabRoutes = [
  { label: "Overview", path: "overview" },
  { label: "Categories", path: "categories" },
  // { label: "Items", path: "items" },
  { label: "Modifier Groups", path: "modifierGroups" },
];

const CustomizedSettings = styled(SettingsOutlined)`
  :hover {
    color: #fff;
  }
`;
const CustomizedButton = styled(Button)`
  :hover svg {
    color: #fff;
  }
`;

const MasterMenuHeader = ({ handleDrawerOpen, open }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const [isBulked, setIsBulked] = useState(false);
  const mdDown = useIsWidthDown("md");
  const mdUp = useIsWidthUp("md");
  const [value, setValue] = useState(pathname || "/menu/overview");
  const {
    menu,
    loading,
    formProduct,
    isDoordashProviderLoading,
    isGrubHubProviderLoading,
  } = useSelector(({ menu }) => menu);
  const { items } = useSelector(({ providers }) => providers);
  const { currentBusiness } = useSelector(({ business }) => business);
  const catProducts = [];
  const categoriesBulk = [];
  const modifiersGroup = [];
  const { isTypeSingle } = isPermissionWithCompanyType();
  const menuBulk = useMemo(() => menu?.bulkInfo?.isSaved, [
    menu,
    menu?.bulkInfo?.isSaved,
  ]);

  useEffect(() => {
    if (menu?.categories?.length) {
      menu.categories.forEach((item) => {
        categoriesBulk.push(item);
        if (item?.products?.length) {
          item.products.forEach((product) => {
            catProducts.push(product);
          });
        }
      });
    }
    if (menu?.modifiersAll?.length) {
      menu.modifiersAll.forEach((item) => {
        modifiersGroup.push(item);
      });
    }

    const checkSaveProducts = catProducts.every(
      (item) => item.bulkInfo?.isSaved
    );
    const checkSaveModifiers = modifiersGroup.every(
      (item) => item.bulkInfo?.isSaved
    );
    const checkSaveCategories = categoriesBulk.every(
      (item) => item.bulkInfo?.isSaved
    );
    setIsBulked(
      !checkSaveProducts ||
        !checkSaveCategories ||
        !checkSaveModifiers ||
        !menuBulk
    );
  }, [menu?.categories, isBulked, catProducts, categoriesBulk, modifiersGroup]);

  useEffect(() => {
    if (!formProduct.bulkInfo?.isSaved) {
      setIsBulked(!formProduct.bulkInfo?.isSaved);
    }
  }, [formProduct.bulkInfo?.isSaved]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  const handleChangeMenuItem = (event) => {
    setValue(event.target.value);
    history.push(event.target.value);
  };

  const linked = useMemo(() => {
    return (
      menu.connectedProviders && linkedWithProvider(menu.connectedProviders)
    );
  }, [menu]);

  const targetConnectedProviders = useMemo(() => {
    const res = { website: true };
    items.forEach((providerItem) => {
      res[providerItem.srv] = providerItem.status == PROVIDER_CONNECTED;
    });
    return res;
  }, [items]);

  const getConnectedDefaultProvider = () => {
    dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: true });
    dispatch({
      type: SET_LINK_PROVIDER,
      payload: {
        connectedProviders: menu.connectedProviders,
        targetConnectedProviders: targetConnectedProviders,
        target: "menu",
      },
    });
  };

  const handleMenuLinkProvider = () => {
    getConnectedDefaultProvider();
  };

  const handleBulk = () => {
    dispatch({ type: SET_LOADING, payload: true });
    if (linked) {
      dispatch(updateBulk());
      // .then(() => {
      //   dispatch({ type: SET_LOADING, payload: true });
      //   if (catProducts) {
      //     dispatch(getMenusV2()).then((res) => {
      //       res.categories.forEach((item) => {
      //         dispatch(getCategoryProductsV2(item._id)).finally(() => {
      //           dispatch({ type: SET_LOADING, payload: false });
      //         });
      //       });
      //     });
      //   }
      //   if (modifiersGroup) {
      //     modifiersGroup.forEach((item) => {
      //       if (!item.bulkInfo?.isSaved) {
      //         dispatch(getModifiersAll()).finally(() => {
      //           dispatch({ type: SET_LOADING, payload: false });
      //         });
      //       }
      //     });
      //   }
      //   if (categoriesBulk) {
      //     categoriesBulk.forEach((item) => {
      //       if (!item.bulkInfo?.isSaved) {
      //         dispatch(getMenusV2()).finally(() => {
      //           dispatch({ type: SET_LOADING, payload: false });
      //         });
      //       }
      //     });
      //   }
      // })
      // .finally(() => {
      //   setTimeout(() => {
      //     dispatch({ type: SET_LOADING, payload: false });
      //   }, 2000);
      // });
    } else {
      getConnectedDefaultProvider();
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  const checkWebsite = useMemo(() => {
    return (
      menu.connectedProviders &&
      checkLinkedProviderWebsite(menu.connectedProviders)
    );
  }, [menu]);

  const handleProviderMap = (provider) => {
    if (provider === MENU_TYPES.doordash) {
      let mapBodyDoorDash = {
        provider: provider,
        action: "map",
        isLinked: true,
      };
      dispatch(doordashMapProvider(mapBodyDoorDash));
    } else if (provider === MENU_TYPES.grubhub) {
      let mapBodyGrubHub = {
        provider: provider,
        action: "map",
        isLinked: true,
      };
      dispatch(grubHubMapProvider(mapBodyGrubHub));
    }
  };
  const checkCurrentBusiness = useMemo(() => {
    return currentBusiness?.roles?.name === "super";
  }, [currentBusiness]);

  return (
    <Box>
      <Box
        sx={{
          pt: 3,
          // width: '100%',
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography sx={{ color: "black", fontSize: "20px" }}>
            {getTitle(pathname)}
          </Typography>
          {isTypeSingle ? (
            <Private permission="MENU_EDIT" disabled={true}>
              <Button
                disabled={
                  (!isBulked && linked) || loading || (linked && !checkWebsite)
                }
                onClick={handleBulk}
                sx={{ ml: 2 }}
                variant="contained"
                startIcon={linked ? <UpdateIcon /> : <LinkOffIcon />}
              >
                {loading
                  ? "Updating..."
                  : linked
                  ? "Update Provider Menus"
                  : "Connect Provider Menus"}
              </Button>
              {linked ? (
                <CustomizedButton
                  onClick={handleMenuLinkProvider}
                  sx={{
                    ml: 2,
                    background: !isBulked ? "rgba(0, 0, 0, 0.12)" : "#E5E5E5",
                    minWidth: "40px",
                    width: "1em",
                    height: "2.62em",
                  }}
                  variant="contained"
                >
                  <CustomizedSettings
                    sx={{ color: "#323232", fontSize: "20px" }}
                  />
                </CustomizedButton>
              ) : (
                <></>
              )}
            </Private>
          ) : (
            <></>
          )}
          {checkCurrentBusiness && (
            <>
              {!menu?.connectedProviders?.doordash && (
                <Button
                  onClick={() => handleProviderMap(MENU_TYPES.doordash)}
                  sx={{ ml: 2 }}
                  variant="contained"
                  // disabled={isDoordashProviderLoading || !menu?.connectedProviders?.doordash}
                  startIcon={
                    isDoordashProviderLoading && <CircularProgress size={14} />
                  }
                >
                  Doordash map
                </Button>
              )}

              {/* <Button
                onClick={() => handleProviderMap(MENU_TYPES.grubhub)}
                sx={{ ml: 2 }}
                variant="contained"
                // disabled={isGrubHubProviderLoading || !menu?.connectedProviders?.grubhub}
                startIcon={isGrubHubProviderLoading && <CircularProgress size={14} />}
              >
                Grubhub map
              </Button> */}
            </>
          )}
        </Box>
      </Box>
      <Hidden mdDown={mdDown}>
        <Tabs value={value} onChange={handleChange}>
          {tabRoutes.map(({ path, label }, index) => (
            <Tab
              sx={{
                pl: index === 0 ? 0 : 2,
                pr: index === tabRoutes.length - 1 ? 0 : 2,
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
              key={uuidv4()}
              value={`/menu/${path}`}
              label={label}
            />
          ))}
        </Tabs>
      </Hidden>
      <Hidden mdUp={mdUp}>
        <FormControl sx={{ mt: 1, mb: 1, border: "none" }}>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            size="small"
            variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={handleChangeMenuItem}
          >
            {tabRoutes.map(({ path, label }, index) => (
              <MenuItem key={uuidv4()} value={`/menu/${path}`}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Hidden>
    </Box>
  );
};

export default MasterMenuHeader;
