import {
  USER_LOADING,
  USER_LOADED,
  USER_LOGOUT,
  IS_LOGIN_USER,
} from "../../constants/user";
import instance, {
  setInstance,
  removeInstance,
  authInstance,
} from "../../services/api";
import { saveFormDataWindowLayer, ocoPatch } from "helpers/googleTagManager";
import { getMenusV2 } from "./Menu/menuV2";
import {
  SET_CURRENT_BUSINESSES_DISABLE,
  SET_CURRENT_BUSINESSES_ENABLE,
} from "./types";
import { getCurrentBusiness } from "./business";

export const signup = (body) => {
  return instance.post("/auth/register", { ...body }).then(() => {
    if (body) {
      const saveFormDataWindowLayerModel = {
        event: "signupSubmit",
        firstName: body.firstName || "",
        lastName: body.lastName || "",
        email: body.email || "",
        phone: body.phone || "",
        restaurantName: body.companyName || "",
        businessPhone: body.businessPhone || "",
      };
      ocoPatch(saveFormDataWindowLayerModel);
      saveFormDataWindowLayer(saveFormDataWindowLayerModel);
    }
  });
};

export const superSignIn = (body) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  return instance
    .post("/auth/super-login", body)
    .then(({ data: { data } }) => {
      localStorage.setItem(
        "businessInfo",
        JSON.stringify({
          id: data.user.business[0].id,
          label: `${data.user.business[0].companyName} - ${
            data?.user?.business[0]?.addressInfo?.address1 ||
            data?.user?.business[0]?.addressInfo?.address ||
            ""
          }`,
        })
      );
      setInstance(data.token, data.user.business[0].id);
      dispatch({
        type: USER_LOADED,
        payload: { data: data.user, authorized: true, loading: false },
      });
      return data.user;
    })
    .catch((err) => {
      dispatch({ type: USER_LOADING, payload: false });
      return Promise.reject(err);
    });
};

export const signIn = (body) => (dispatch) => {
  body.email = body.email.trim();
  dispatch({ type: USER_LOADING, payload: true });
  return authInstance
    .post("/login-v2", body)
    .then(({ data: { data } }) => {
      if (body) {
        const saveFormDataWindowLayerModel = {
          email: body.email || "",
          event: "loginSuccess",
        };
        ocoPatch(saveFormDataWindowLayerModel);
        saveFormDataWindowLayer(saveFormDataWindowLayerModel);
      }
      setInstance(data.token, data.user.business[0].id);
      dispatch(getUserData());
      dispatch({
        type: USER_LOADED,
        payload: { data: data.user, authorized: true, loading: false },
      });
      dispatch({ type: IS_LOGIN_USER, payload: true });
      return data.user;
    })
    .catch((err) => {
      dispatch({ type: USER_LOADING, payload: false });
      return Promise.reject(err);
    });
};

export const forgotPassword = (body) => {
  return instance
    .post("/auth/forgot", body)
    .then(({ data: { data } }) => {
      setInstance(data.token);
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const logout = () => (dispatch) => {
  authInstance.get("/logout").then(() => {
    dispatch({ type: "RESET_MENUS" });
  });
  dispatch({ type: USER_LOGOUT });
  removeInstance();
};

export const inviteUser = (body) => {
  return instance.post("/auth/create-user", body);
};

export const signupUser = (body) => (dispatch) => {
  return instance
    .post("/auth/complete-signup", body)
    .then(({ data: { data } }) => {
      localStorage.removeItem("selectedBrand");
      localStorage.removeItem("selectedLocation");
      localStorage.removeItem("businessInfo");
      setInstance(data.token);

      dispatch(getUserData()).then(() => {
        window.location.href = "/";
      });
      return data.user;
    });
};

export const getUserData = () => (dispatch) => {
  return instance
    .get("/users/me")
    .then(({ data: { data } }) => {
      const userBusinessFromStorage = JSON.parse(
        localStorage.getItem("businessInfo")
      );
      data.business.forEach((item) => {
        item.roles = item.roles?.find((role) => role.userId === data._id)?.role;
      });
      const currentBusiness =
        data.business.find(
          (item) => item._id === userBusinessFromStorage?.id
        ) || data.business[0];
      dispatch(getCurrentBusiness(currentBusiness?._id, data._id));
      // currentBusiness?.type
      //   ? localStorage.setItem('type', currentBusiness?.type)
      //   : localStorage.setItem('type', companyTypesEnum.SINGLE);
      // if (!userBusinessFromStorage?.id || userBusinessFromStorage === 'null') {
      // localStorage.setItem(
      //   'businessInfo',
      //   JSON.stringify({
      //     label: `${currentBusiness.companyName} - ${
      //       currentBusiness.address || currentBusiness?.business.addressInfo?.street1 || ''
      //     }`,
      //     id: currentBusiness?.business?.id,
      //   })
      // );
      // }
      data.businessList = [...data.business];
      data.business = currentBusiness;
      // dispatch({ type: SET_CURRENT_BUSINESSES, payload: currentBusiness });
      dispatch({ type: USER_LOADED, payload: { data } });
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: USER_LOADING, payload: false });
    });
};

export const updateUserSession = () => (dispatch) => {
  return instance.put("/users/session").then(({ data: { data } }) => {
    dispatch(getUserData());
    /**
      * In here we need to set user data from response
      @business - {array}
    */

    // const userBusinessFromStorage = localStorage.getItem('business');
    // data.business.forEach((item) => {
    //   item.roles = item.roles?.find((role) => role.userId === data._id)?.role;
    // });
    // const currentBusiness =
    //   data.business.find((item) => item._id === userBusinessFromStorage) || data.business[0] || [];
    // if (!userBusinessFromStorage) {
    //   localStorage.setItem('business', data.business[0]?._id);
    // }
    // data.businessList = [...data.business];
    // data.business = currentBusiness;
    // dispatch({ type: SET_CURRENT_BUSINESSES, payload: currentBusiness });
    // dispatch({ type: USER_LOADED, payload: { data } });
    // dispatch(getMenusV2());
  });
};

export const updateUserData = (body) => (dispatch, getState) => {
  const { user } = getState();
  return instance.put("/users/me", body).then(() => {
    dispatch({
      type: USER_LOADED,
      payload: { data: { ...user.data, ...body }, loading: false },
    });
  });
};

export const updateUserPassword = (body) => {
  return instance.post("/users/change-password", body);
};

export const sendMessage = (body) => {
  return instance.post("/users/help", body);
};

export const updateUserMailbox = (body) => (dispatch) => {
  return instance.post("/users/update-mailbox", body).then(() => {
    dispatch(getUserData());
  });
};

export const verifyUser = (data) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  return instance
    .post("/users/me/email/verify", data)
    .then((res) => res)
    .finally(() => {
      dispatch({ type: USER_LOADING, payload: false });
    });
};

export const confirmUser = (body) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  return instance
    .post("/users/me/email/confirm", body)
    .then(({ data: { data } }) => {
      setInstance(data.token);
      return data;
    })
    .finally(() => {
      dispatch({ type: USER_LOADING, payload: false });
    });
};

export const confirmPassword = (body) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  return instance
    .post("/users/change-password", body)
    .then(({ data: { data } }) => {
      setInstance(data.token);
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({
        type: USER_LOADING,
        payload: false,
      });
    });
};

export const updateUserBilling = (body) => {
  return instance.put(`/billing`, body);
};

export const deleteUserBilling = (id) => {
  return instance.delete(`/billing/card/${id}`);
};

export const getUserBilling = (body) => {
  return instance.get(`/billing`, body);
};

export const getPlans = () => {
  return instance.get(`/billing/plans`);
};

export const getEmployers = () => {
  return instance.get("/users/employers").then(({ data: { data } }) => data);
};

export const deleteEmployer = (id) => {
  return instance.delete(`/auth/employers/${id}`);
};

// Disabled and Enabled Admin.

//  https://api01.demo.orders.co/disable/:id
export const disabledAdmin = (id) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });

  return instance
    .put(`business/disable/${id}`, { id })
    .then((data) => {
      dispatch({ type: SET_CURRENT_BUSINESSES_DISABLE });
      dispatch({ type: USER_LOADING, payload: false });
    })
    .catch(() => {
      dispatch({ type: USER_LOADING, payload: false });
    });
};

// https://api01.demo.orders.co/enable/:id
export const enabledAdmin = (id) => (dispatch) => {
  return instance
    .put(`business/enable/${id}`, { id })
    .then((data) => {
      dispatch({ type: SET_CURRENT_BUSINESSES_ENABLE });
      dispatch({ type: USER_LOADING, payload: false });
    })
    .catch(() => {
      dispatch({ type: USER_LOADING, payload: false });
    });
};

export const downloadsMenuByProvider = (provider) => (dispatch) => {
  return instance.post(`menu/sync/${provider}`, {}).then(() => {
    dispatch(getMenusV2());
  });
};

export const getQr = () => {
  return instance.get("/users/qr").then(({ data: { data } }) => {
    return data.url;
  });
};

export const getQrForSuperAdmin = (businessId) => {
  return instance
    .get(`/business/qr/${businessId}`)
    .then(({ data: { data } }) => {
      return data.url;
    });
};
